import React from "react"
import Layout from "../../components/litjen-layout"
import SEO from "../../components/litjen-seo"

const LitjenAdventskalender = () => (
  <Layout>
    <SEO title="En Litjen adventskalender" />
    <div>
      <h1>En Litjen adventskalender</h1>
    </div>
  </Layout>
)

export default LitjenAdventskalender